import React, { useCallback, useMemo } from 'react';
import {
    Alert as MuiAlert,
    Box,
    CircularProgress,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { apiClient, useEntityData, User } from 'c-data';
import { Alert, AutoGrid, Button, EntityField } from 'c-components';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { Campaign, Client, PermissionName } from '@uniled/api-sdk';
import { useAPIClientRequest, useDateUtils } from 'c-hooks';
import { UserProfilePopOver } from 'c-admin/Components';
import { NetworkRequestState } from '@uniled/data-layer';
import to from 'await-to-js';
import { CheckCircle } from '@mui/icons-material';
import { cloudDownloadIcon, deleteIcon, refreshIcon } from 'c-main/icons';

type Props = {
    id: number;
    buttons?: boolean;
};

const clientReadPerms = [PermissionName.UniledportalClientRead];
const dateReadPerms = [PermissionName.UniledportalDateRead];
function formatDateString(dateString: string) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const timeZoneCode = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
        .formatToParts(date)
        .find(part => part.type === 'timeZoneName').value;

    return `${day}/${month}/${year} ${hours}:${minutes} ${timeZoneCode}`;
}

const CampaignOverviewDetails: React.FC<Props> = ({ id, buttons = false }) => {
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const { formatDateStringIgnoreTime } = useDateUtils();
    const {
        start: runClientStart,
        requestState: runClientState,
        error: runClientError,
        reset: resetRunClient,
    } = useAPIClientRequest(apiClient.Reports.pcaRunReport);

    const {
        start: downloadClientStart,
        requestState: downloadClientState,
        error: downloadClientError,
        reset: resetDownloadClient,
    } = useAPIClientRequest(apiClient.Entities.Campaign.pcaExport);

    const {
        start: deleteClientStart,
        requestState: deleteClientState,
        error: deleteClientError,
        reset: resetDeleteClient,
    } = useAPIClientRequest(apiClient.Reports.pcaDeleteReport);

    const {
        start: runInternalStart,
        requestState: runInternalState,
        error: runInternalError,
        reset: resetRunInternal,
    } = useAPIClientRequest(apiClient.Reports.pcaRunReport);

    const {
        start: downloadInternalStart,
        requestState: downloadInternalState,
        error: downloadInternalError,
        reset: resetDownloadInternal,
    } = useAPIClientRequest(apiClient.Entities.Campaign.pcaExport);

    const {
        start: deleteInternalStart,
        requestState: deleteInternalState,
        error: deleteInternalError,
        reset: resetDeleteInternal,
    } = useAPIClientRequest(apiClient.Reports.pcaDeleteReport);

    const handleAction = useCallback(
        async (action: 'run' | 'download' | 'delete', environment: 'live' | 'sandbox') => {
            let apiCall;
            let resetFn;
            let error = null;

            if (action === 'run') {
                apiCall = environment === 'live' ? runClientStart : runInternalStart;
                resetFn = environment === 'live' ? resetRunClient : resetRunInternal;
            }
            if (action === 'download') {
                apiCall = environment === 'live' ? downloadClientStart : downloadInternalStart;
                resetFn = environment === 'live' ? resetDownloadClient : resetDownloadInternal;
            }
            if (action === 'delete') {
                apiCall = environment === 'live' ? deleteClientStart : deleteInternalStart;
                resetFn = environment === 'live' ? resetDeleteClient : resetDeleteInternal;
            }

            const formData = new FormData();
            formData.append('environment', environment);

            if (action === 'download') {
                const [err] = await to(apiCall(Number(id), formData));
                error = err;
            } else if (action === 'run') {
                const [err] = await to(apiCall({ id, environment, consumerType: 'full' }));
                error = err;
            } else if (action === 'delete') {
                const [err] = await to(apiCall({ id, environment }));
                error = err;
            }

            if (error) {
                setTimeout(() => {
                    resetFn();
                }, 10000);
            } else {
                setTimeout(() => {
                    resetFn();
                }, 2000);
            }
        },
        [
            runClientStart,
            downloadClientStart,
            deleteClientStart,
            runInternalStart,
            downloadInternalStart,
            deleteInternalStart,
            resetRunClient,
            resetDownloadClient,
            resetDeleteClient,
            resetRunInternal,
            resetDownloadInternal,
            resetDeleteInternal,
            id,
        ],
    );
    const t = useCommonTranslation();

    return useMemo(
        () => (
            <Box>
                <AutoGrid spacing={2}>
                    <Block
                        title={
                            <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.bookedByHeader" />
                        }
                        value={<BookedBy campaign={campaign} />}
                    />
                    <IfHasAllPermissions permissions={clientReadPerms}>
                        <Block
                            title={
                                <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.brandHeader" />
                            }
                            value={
                                <EntityField<Client>
                                    id={campaign?.client_id}
                                    entityName="Client"
                                    field="name"
                                />
                            }
                        />
                    </IfHasAllPermissions>
                    <IfHasAllPermissions permissions={dateReadPerms}>
                        <Block
                            title={
                                <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.duration" />
                            }
                            value={
                                <>
                                    {formatDateStringIgnoreTime({
                                        date: campaign?.date?.date_start,
                                    })}{' '}
                                    -{' '}
                                    {formatDateStringIgnoreTime({
                                        date: campaign?.date?.date_end,
                                    })}
                                </>
                            }
                        />
                    </IfHasAllPermissions>
                    {buttons && (
                        <IfHasAllPermissions permissions={[PermissionName.Admin]}>
                            <Stack direction="row" gap={5} pl={6}>
                                <Box>
                                    <Stack direction="row">
                                        <Typography color="grey.500">
                                            <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.client" />
                                        </Typography>
                                        <ButtonWithState
                                            action="run"
                                            environment="live"
                                            handleAction={handleAction}
                                            requestState={runClientState}
                                            error={runClientError}
                                        />
                                        <ButtonWithState
                                            action="download"
                                            environment="live"
                                            handleAction={handleAction}
                                            requestState={downloadClientState}
                                            error={downloadClientError}
                                        />
                                        <ButtonWithState
                                            action="delete"
                                            environment="live"
                                            handleAction={handleAction}
                                            requestState={deleteClientState}
                                            error={deleteClientError}
                                        />
                                    </Stack>
                                    <Typography>
                                        {formatDateString(campaign.pca_last_run_at)}
                                    </Typography>
                                </Box>

                                <Box>
                                    <Stack direction="row">
                                        <Typography color="grey.500">
                                            <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.internal" />
                                        </Typography>
                                        <ButtonWithState
                                            action="run"
                                            environment="sandbox"
                                            handleAction={handleAction}
                                            requestState={runInternalState}
                                            error={runInternalError}
                                        />
                                        <ButtonWithState
                                            action="download"
                                            environment="sandbox"
                                            handleAction={handleAction}
                                            requestState={downloadInternalState}
                                            error={downloadInternalError}
                                        />
                                        <ButtonWithState
                                            action="delete"
                                            environment="sandbox"
                                            handleAction={handleAction}
                                            requestState={deleteInternalState}
                                            error={deleteInternalError}
                                        />
                                    </Stack>
                                    <Typography>
                                        {formatDateString(campaign.pca_last_run_at_sandbox)}
                                    </Typography>
                                </Box>
                            </Stack>
                            {(downloadClientState === NetworkRequestState.Success ||
                                downloadInternalState === NetworkRequestState.Success) && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.downloadSuccess')}
                                </Alert>
                            )}
                            {runInternalError && (
                                <Alert severity="error" variant="outlined">
                                    {String(runInternalError)}
                                </Alert>
                            )}
                            {runClientError && (
                                <Alert severity="error" variant="outlined">
                                    {String(runClientError)}
                                </Alert>
                            )}
                            {downloadInternalError && (
                                <Alert severity="error" variant="outlined">
                                    {String(downloadInternalError)}
                                </Alert>
                            )}
                            {downloadClientError && (
                                <Alert severity="error" variant="outlined">
                                    {String(downloadClientError)}
                                </Alert>
                            )}
                            {deleteInternalError && (
                                <Alert severity="error" variant="outlined">
                                    {String(deleteInternalError)}
                                </Alert>
                            )}
                            {deleteClientError && (
                                <Alert severity="error" variant="outlined">
                                    {String(deleteClientError)}
                                </Alert>
                            )}
                            {deleteInternalState === NetworkRequestState.Success && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.deleteSuccess')}
                                </Alert>
                            )}
                            {deleteClientState === NetworkRequestState.Success && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.deleteSuccess')}
                                </Alert>
                            )}
                            {runInternalState === NetworkRequestState.Success && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.runSuccess')}
                                </Alert>
                            )}
                            {runClientState === NetworkRequestState.Success && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.runSuccess')}
                                </Alert>
                            )}
                        </IfHasAllPermissions>
                    )}
                </AutoGrid>
                {campaign?.flags?.under_configuration === true && (
                    <MuiAlert severity="warning" variant="outlined">
                        <Translate path="Modules.Main.Campaigns.Overview.underConstructionWarning" />
                    </MuiAlert>
                )}
            </Box>
        ),
        [
            buttons,
            campaign,
            deleteClientError,
            deleteClientState,
            deleteInternalError,
            deleteInternalState,
            downloadClientError,
            downloadClientState,
            downloadInternalError,
            downloadInternalState,
            formatDateStringIgnoreTime,
            handleAction,
            runClientError,
            runClientState,
            runInternalError,
            runInternalState,
            t,
        ],
    );
};

const Block: React.FC<{ title: React.ReactNode; value: React.ReactNode }> = ({ title, value }) => (
    <Box>
        <Typography color="grey.500">{title}</Typography>
        <Typography sx={{ maxWidth: '20em', wordBreak: 'break-word' }} component="div">
            {value}
        </Typography>
    </Box>
);

const ButtonWithState = ({
    action,
    environment,
    handleAction,
    requestState,
    error,
}: {
    action: 'run' | 'download' | 'delete';
    environment: string;
    handleAction: (action: string, environment: string) => Promise<void>;
    requestState: NetworkRequestState;
    error: string | null;
}) => {
    const isLoading = requestState === NetworkRequestState.InProgress;
    const isSuccess = requestState === NetworkRequestState.Success;

    const getIcon = () => {
        switch (action) {
            case 'run':
                return refreshIcon;
            case 'download':
                return cloudDownloadIcon;
            case 'delete':
                return deleteIcon;
            default:
                return null;
        }
    };

    return (
        <Stack>
            <IconButton
                onClick={() => handleAction(action, environment)}
                disabled={isLoading || isSuccess}
                color={action === 'delete' ? 'error' : 'primary'}
                size="small"
            >
                {isLoading ? (
                    <CircularProgress size={20} />
                ) : isSuccess ? (
                    <CheckCircle color="success" />
                ) : (
                    getIcon()
                )}
            </IconButton>
        </Stack>
    );
};

const BookedBy: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
    const userId = campaign.user_id;
    const { getById, getLoadingById } = useEntityData<User>('User');
    const user = getById({ id: userId });

    const isLoading = useMemo(
        () => getLoadingById(userId).state === NetworkRequestState.InProgress,
        [userId, getLoadingById],
    );
    const username = useMemo(
        () =>
            user ? (
                <UserProfilePopOver userId={userId}>
                    <Box>
                        <Button variant="text">{user?.name}</Button>
                    </Box>
                </UserProfilePopOver>
            ) : null,
        [user, userId],
    );
    if (!user && isLoading)
        return (
            <>
                <CircularProgress size={15} />
            </>
        );

    if (!user) return <>-</>;

    return <>{username}</>;
};

export default CampaignOverviewDetails;
